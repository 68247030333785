import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Danger from "components/Typography/Danger.js";
// import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
  const classes = useStyles();
  return (
    <div className={classes.pricingSection}>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
              <h6
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                试用版
              </h6>
              <h1 className={classes.cardTitle}>免费</h1>
              <ul>
                <li>
                  <Danger>免费回答 10 次</Danger>
                </li>
                <li>
                  <Check /> 法规截图查看
                </li>
                <li>
                  <Check /> 法规原文查看
                </li>
                <li>
                  <Danger>
                    <Close /> 法规原文下载
                  </Danger>
                </li>
              </ul>
              <Button href="https://know.idute.com.cn/" color="info" round>
                立即试用
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card raised pricing color="info">
            <CardBody pricing>
              <h6 className={classes.cardCategory}>进阶版</h6>
              <h1 className={classes.cardTitleWhite}>
                <small>￥</small>299<small>/年</small>
              </h1>
              <ul>
                <li>
                  <Check /> 回答次数
                </li>
                <li>
                  <Check /> 法规截图查看
                </li>
                <li>
                  <Check /> 法规原文查看
                </li>
                <li>
                  <Check /> 法规原文下载
                </li>
              </ul>
              <Button href="https://know.idute.com.cn/" color="info" round>
                立即试用
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
              <h6
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                普通版
              </h6>
              <h1 className={classes.cardTitle}>
                <small>￥</small>199<small>/年</small>
              </h1>
              <ul>
                <li>
                  <Check /> 回答次数
                </li>
                <li>
                  <Check /> 法规截图查看
                </li>
                <li>
                  <Check /> 法规原文查看
                </li>
                <li>
                  <Danger>
                    <Close /> 法规原文下载
                  </Danger>
                </li>
              </ul>
              <Button href="https://know.idute.com.cn/" color="info" round>
                立即试用
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
