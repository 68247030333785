import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import { Chat, CloudDownload, Update } from "@material-ui/icons";

import featuresStyle from "assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures() {
  const classes = useStyles();
  return (
    <div className={classes.featuresSection}>
      <div className={classes.textCenter}>
        <h3 className={classes.title}>让每个人都能成为 EHS 领域的法规专家</h3>
      </div>
      <GridContainer>
        <GridItem md={4} sm={4} className={classes.mlAuto}>
          <InfoArea
            customStyle={{ paddingTop: 0 }}
            title="AI智能定位法规条款，让回答有理有据"
            icon={QuestionAnswer}
            iconColor="info"
          />
        </GridItem>
        <GridItem md={4} sm={4} className={classes.mrAuto}>
          <InfoArea
            customStyle={{ paddingTop: 0 }}
            title="告别传统关键词搜索，采用 AI 问答的形式，提高搜索效率"
            icon={Chat}
            iconColor="success"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4} className={classes.mlAuto}>
          <InfoArea
            customStyle={{ paddingTop: 0 }}
            title="内置海量法规库，支持查看和下载原文"
            icon={CloudDownload}
            iconColor="success"
          />
        </GridItem>
        <GridItem md={4} sm={4} className={classes.mrAuto}>
          <InfoArea
            customStyle={{ paddingTop: 0 }}
            title="定期更新法律法规"
            icon={Update}
            iconColor="rose"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
