import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationPC from "assets/img/assets-for-demo/presentationViewSectionComponent/desktop.png";
import presentationMobile from "assets/img/assets-for-demo/presentationViewSectionComponent/mobile.png";
import presentationIpad from "assets/img/assets-for-demo/presentationViewSectionComponent/tablet.png";

const useStyles = makeStyles(contentStyle);

export default function SectionContent() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>跨平台EHS管理新体验</h3>
              <h6 className={classes.description}>
                电脑、平板、安卓手机、苹果手机
              </h6>
              <h5 className={classes.description}>
                跨平台应用满足您所有使用需求,支持电脑、平板、安卓手机和苹果手机,轻松实现数据收集、任务指派、通知提醒等功能,让您的EHS工作零障碍{" "}
              </h5>
            </div>
          </GridItem>
          <GridItem md={7} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <div className={classes.animeAreaImg}>
                <ScrollAnimation animateIn="animate__fadeInUp">
                  <img
                    src={presentationMobile}
                    alt="Mobile"
                    className={classes.areaImg}
                  />
                </ScrollAnimation>
              </div>
              <div className={classes.animeInfoImg}>
                <ScrollAnimation animateIn="animate__fadeInUp">
                  <img
                    src={presentationIpad}
                    alt="iPad"
                    className={classes.infoImg}
                  />
                </ScrollAnimation>
              </div>
              <img className={classes.ipadImg} src={presentationPC} alt="PC" />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
