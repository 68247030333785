import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import image1 from "../../../assets/img/69c6f98a5d9e19779fc8c8112174e06c.jpg";
import image2 from "../../..//assets/img/0907f9a3343a7caefc2fc60f97457a25.jpg";
import image3 from "../../..//assets/img/a2b670254abd07751811ca961d7abcb0.jpg";
import image4 from "../../..//assets/img/f6caef2f9bb64144c5c3defa209bfbbc.jpg";

export default function SectionPreview() {
  const images = [
    {
      original: image1,
      thumbnail: image1,
    },
    {
      original: image2,
      thumbnail: image2,
    },
    {
      original: image3,
      thumbnail: image3,
    },
    {
      original: image4,
      thumbnail: image4,
    },
  ];
  return (
    <div className="slider-container">
      <ImageGallery
        items={images}
        autoPlay
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
      />
    </div>
  );
}
