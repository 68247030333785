import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            上海都特企业咨询管理有限公司成立于2019年，是一支拥有丰富的企业EHS（环境保护、职业健康、安全生产）管理经验和国际化视野的专业团队，致力于为各类企业提供EHS相关标准化管理、信息咨询服务、技术服务等工作。
            公司服务领域涵盖了石油化工、电力、冶金、建筑施工、物流仓储、机械制造、大型商贸综合体等。
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
