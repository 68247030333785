import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
// import Close from "@material-ui/icons/Close";
// import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import Button from "components/CustomButtons/Button.js";
// import Danger from "components/Typography/Danger.js";

import freeDemoStyle from "assets/jss/material-kit-pro-react/views/presentationSections/freeDemoStyle.js";

const useStyles = makeStyles(freeDemoStyle);

export default function SectionFreeDemo() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem
            md={5}
            sm={8}
            className={classes.mlAuto + " " + classes.mrAuto}
          >
            {/* <div className={classes.iconGithub}>
              <div className="fab fa-github" />
            </div> */}
            <div>
              <h2 className={classes.title}>云端SaaS、本地部署二选一</h2>
              <h5 className={classes.description}>
                我们的产品既支持云端SaaS部署，也可部署在本地数据中心，实现双模架构，让您根据实际业务需求选择最合适的部署方式
              </h5>
            </div>
            {/* <Button
              href="https://demos.creative-tim.com/material-kit-react/#/?ref=mkpr-free-demo-section-presentation"
              color="rose"
              target="_blank"
              round
            >
              View demo on github
            </Button> */}
          </GridItem>
          <GridItem md={6} sm={12} className={classes.mlAuto}>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <Card className={classNames(classes.card, classes.cardPricing)}>
                  <CardBody>
                    <h3 className={classes.cardTitle}>SaaS云端部署</h3>
                    <ul>
                      <li>
                        <b>数据安全可靠，运维免维护</b>
                      </li>
                      <li>
                        <b>使用灵活方便，随时随地访问</b>
                      </li>
                      <li>
                        <b>自动应用升，无需手动更新</b>
                      </li>
                      <li>
                        <b>与其他云服务融合简单方便</b>
                      </li>
                      <li>
                        <b>7x24小时专业技术支持</b>
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem md={6} sm={6}>
                <Card className={classNames(classes.card, classes.cardPricing)}>
                  <CardBody>
                    <h3 className={classes.cardTitle}>本地部署</h3>
                    <ul>
                      <li>
                        <b>数据权属明确，存储自主可控</b>
                      </li>
                      <li>
                        <b>可深度集成公司既有系统</b>
                      </li>
                      <li>
                        <b>更好满足定制化需求</b>
                      </li>
                      <li>
                        <b>灵活的安全策略和访问控制</b>
                      </li>
                      <li>
                        <b>提高敏感数据处理的安全性</b>
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
