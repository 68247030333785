import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import QrImg from "assets/img/qr.png";

const useStyles = makeStyles(contactsStyle);

export default function SectionContacts({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Contact us 1 START */}
      <div className={classes.contacts}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5}>
              <h2 className={classes.title}>联系我们</h2>
              <h5 className={classes.description}>
                如果您对我们的产品和服务有疑问，欢迎随时联系我们！
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="联系地址"
                description={<span>上海市嘉定区环城路2222号1幢J</span>}
                icon={PinDrop}
              />
              <InfoArea
                className={classes.infoArea}
                title="联系电话"
                description={<span>13511657703</span>}
                icon={Phone}
              />
              <InfoArea
                className={classes.infoArea}
                title="联系邮箱"
                description={<span>carl.he@idute.com.cn</span>}
                icon={Email}
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} style={{ textAlign: "center" }}>
              <img
                style={{ width: "15rem", marginTop: "24px" }}
                src={QrImg}
                alt="企微二维码"
              />
              <p
                style={{
                  color: "#999",
                  fontSize: "0.8rem",
                  marginTop: "0.7rem",
                }}
              >
                扫描二维码，添加我们的企业微信
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Contact us 1 END */}
    </div>
  );
}
