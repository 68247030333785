/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import SectionFeatures from "views/LegislationPage/Sections/SectionFeatures.js";
import SectionPreview from "views/LegislationPage/Sections/SectionPreview";
import SectionPricing from "views/LegislationPage/Sections/SectionPricing";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingStyle.js";


const useStyles = makeStyles(pricingStyle);

export default function PricingPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="EHS 致知"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />

      <Parallax
        image={require("assets/img/bg2.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>EHS 致知</h1>
              <h2 style={{ color: '#fff' }}>
                EHS 领域首个 AI 法律法规工具
              </h2>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionFeatures />
          <hr />
          <SectionPreview />
          <hr />
          <SectionPricing />

        </div>
      </div>
      <Footer
        // theme="white"
        content={
          <div>
            <div className={classes.left}>
              {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.ehs.cn/"
                    className={classes.block}
                    target="_blank"
                  >
                    环境健康安全网
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.ehscity.com/"
                    className={classes.block}
                    target="_blank"
                  >
                    EHSCITY
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="http://www.ehslaws.cn/"
                    className={classes.block}
                    target="_blank"
                  >
                    环境安全法规网
                  </a>
                </ListItem>
              </List> */}
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} {" "}
              上海都特企业咨询管理有限公司{" "}
              <a
                href="http://beian.miit.gov.cn/"
                  className={classes.inlineBlock}
                  target="_blank"
                >
                  沪ICP备2021024472号
              </a>
            </div>
          </div>
        }
      />
    </div>
  );
}
